import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//images
import logowhite from "./../assets/images/forsalogowhite.png";
import logo from "./../assets/images/forsalogo.png";
import ContactUs from "../pages/ContactUs";

export default function Header5() {
  //Sidebar
  const [openToggleBtn, setOpenToggleBtn] = useState(false);

  /* for sticky header */
  const [headerFix, setheaderFix] = React.useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 130);
    });
    var navUl = [].slice.call(document.querySelectorAll(".navbar-nav > li"));
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener("click", function () {
        checkLi(this);
      });
    }
    function checkLi(current) {
      //SubMenu.forEach(el => (current !== el) ? el.classList.remove('none') : '');
      navUl.forEach((el) =>
        current !== el ? el.classList.remove("open") : ""
      );
      setTimeout(() => {
        current.classList.toggle("open");
      }, 100);
    }
  }, []);

  // Serach btn
  const [openSearchTab, setOpenSearchTab] = useState(false);

  return (
    <>
      <header className="site-header mo-left header style-5">
        <div className="top-bar">
          <div className="container">
            <div className="dz-topbar-inner d-flex justify-content-between align-items-center">
              <div className="dz-topbar-left">
                <ul>
                  <li>
                    <a href="mailto:info@forsacenter.com">
                      <i className="fas fa-envelope"></i>
                      info@forsacenter.com
                    </a>
                  </li>
                  <li>
                    <i className="fas fa-map-marker-alt"></i>
                    Awajan, Zarqa, Jordan
                  </li>
                </ul>
              </div>
              <div className="dz-topbar-right">
                <ul className="dz-social">
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      target="_blank"
                      className="fab fa-facebook-f"
                      href="https://www.facebook.com/forsaemc"
                    ></a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      target="_blank"
                      className="fab fa-instagram"
                      href="https://www.instagram.com/forsaemc"
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="middle-bar bg-white">
          <div className="container">
            {/* <!-- website logo  --> */}
            <div className="middle-area">
              <div className="logo-header logo-dark">
                <Link to={"./"}>
                  <img src={logo} alt="" />
                </Link>
              </div>
              <div className="logo-header logo-white">
                <Link to={"#"}>
                  <img src={logowhite} alt="" />
                </Link>
              </div>
              <div className="extra-nav">
                <div className="service-info">
                  <span>Office</span>
                  <a href="tel:00962777375404">
                    <h4 className="title">(+962) 777375404</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Main Header --> */}
        <div
          className={`sticky-header main-bar-wraper navbar-expand-lg  ${
            headerFix ? "is-fixed" : ""
          }`}
        >
          <div className="main-bar clearfix ">
            <div className="container clearfix">
              {/* <!-- Website Logo --> */}
              <div className="logo-header mostion logo-white  d-md-block d-lg-none">
                <Link to={"./"}>
                  <img src={logowhite} alt="" />
                </Link>
              </div>
              {/* <!-- Nav Toggle Button --> */}
              <button
                className={`navbar-toggler navicon justify-content-end ${
                  openToggleBtn ? "open" : "collapsed"
                }`}
                onClick={() => setOpenToggleBtn(!openToggleBtn)}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              {/* <!-- Extra Nav --> */}
              <div className="extra-nav">
                <div className="extra-cell">
                  <Link
                    to={"./contact-us"}
                    className="btn btn-primary d-sm-inline-flex d-none btn-border btn-border-white m-r10 m-b10"
                  >
                    CONTACT US
                  </Link>
                </div>
              </div>
              {/* <!-- Extra Nav --> */}

              <div
                className={`header-nav navbar-collapse justify-content-start ${
                  openToggleBtn ? " collapse show" : " collapse"
                }`}
              >
                <div className="logo-header ">
                  <Link to={"./"}>
                    <img src={logo} alt="" />
                  </Link>
                </div>
                <ul className="nav navbar-nav navbar navbar-left">
                  <li className="nav-item">
                    <Link to={"/"}>
                      <span>Home</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/services"}>Services</Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/about-us"}>
                      <span>About</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/contact-us"}>
                      <span>Contact</span>
                    </Link>
                  </li>
                </ul>
                <div className="dz-social-icon">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/?hl=en"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Main Header End --> */}
      </header>
    </>
  );
}
