import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import swal from "sweetalert2";

//Layouts
import Header from "./../layouts/Header5";
import Footer from "./../layouts/Footer5";
import PageTitle from "./../layouts/PageTitle";

//Components
import ClientsSlider from "./../components/ClientsSlider";
import FooterSingUp from "./../components/FooterSingUp";

//images
import bg18 from "./../assets/images/background/bg18.jpg";

export default function ContactUs() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    //emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
    emailjs
      .sendForm(
        "service_gfykn6i",
        "template_iy1pb0b",
        e.target,
        "HccoOtZS6GHw-N-m6"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    swal("Good job!", "form successfuly submmited", "success");
  };
  return (
    <>
      <Header />
      <div className="page-content bg-white">
        <PageTitle title="Contact Us" parentPage="Home" childPage="Pages" />
        <section className="content-inner-2 pt-0">
          <div className="map-iframe">
            <iframe
              title="contact"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8523.03466050228!2d36.072541285398685!3d32.03220935821142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151b6521ddf7aac1%3A0xbd3d3d5047ef3d83!2sForsa%20Electromechanics%20Center!5e0!3m2!1sen!2sjo!4v1705276988453!5m2!1sen!2sjo"
              style={{
                border: "0",
                width: "100%",
                minHeight: "100%",
                marginBottom: "-8px",
              }}
              allowFullScreen
            ></iframe>
          </div>
        </section>
        <section
          className="contact-wraper1"
          style={{ backgroundImage: "url(" + bg18 + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="contact-info">
                  <div className="section-head text-white style-1">
                    <h3 className="title text-white">Get In Touch</h3>
                    <p>
                      If you are interested in working with us, please get in
                      touch.
                    </p>
                  </div>
                  <ul className="no-margin">
                    <li className="icon-bx-wraper text-white left m-b30">
                      <div className="icon-md">
                        <span className="icon-cell">
                          <i className="flaticon-placeholder-1"></i>
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className=" dz-tilte text-white">Our Address</h4>
                        <p className="font-18">
                          Al malik talal, Awajan, Zarqa, Jordan
                        </p>
                      </div>
                    </li>
                    <li className="icon-bx-wraper text-white left m-b30">
                      <div className="icon-md">
                        <span className="icon-cell">
                          <i className="flaticon-message"></i>
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className="dz-tilte text-white">Our Email</h4>
                        <p className="font-18">info@forsacenter.com</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-full content-inner-5">
          <div className="container">
            <ClientsSlider />
          </div>
        </section>
        <section className="section-full dz-subscribe style-1">
          <FooterSingUp />
        </section>
      </div>
      <Footer />
    </>
  );
}
