import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";

//Images
import logowhite from "./../assets/images/forsalogowhite.png";
import RecentBlog1 from "./../assets/images/blog/recent-blog/pic1.jpg";

function Footer5() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    //emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
    emailjs
      .sendForm(
        "gmail",
        "YOUR_TEMPLATE_ID",
        e.target,
        "d9b2e0f5fc72cb94792110e8ff2028f3-us16"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <footer className="site-footer style-5" id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row m-b30">
              <div
                className="col-lg-5 aos-item"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="200"
              >
                <div className="widget widget_about">
                  <div className="footer-logo logo-white">
                    <Link to={"./"}>
                      <img src={logowhite} alt="" />
                    </Link>
                  </div>
                  <p>
                    Introducing Forsa Center, Al-Zarqa's pioneer in
                    electromechanics. With a decade of kingdom-wide expertise,
                    we redefine quality in every project
                  </p>
                  <div className="dz-social-icon">
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.facebook.com/ForsaEMC/"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.instagram.com/forsaemc"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-xl-4 col-lg-4 col-sm-8 aos-item"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="200"
              >
                <div className="widget widget_about">
                  <h4 className="footer-title">Contact Us</h4>
                  <div className="widget widget_getintuch">
                    <ul>
                      <li>
                        <i className="flaticon-placeholder"></i>
                        <span>Awajan, Zarqa, Jordan</span>
                      </li>
                      <li>
                        <i className="flaticon-call"></i>
                        <span>
                          0777375404 <br /> 0780394138
                        </span>
                      </li>
                      <li>
                        <i className="flaticon-chat-1"></i>
                        <span>
                          <a href="mailto:info@forsacenter.com" target="_blank">
                            info@forsacenter.com
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-2 col-lg-2 col-sm-4 col-6 aos-item"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="400"
              >
                <div className="widget widget_services">
                  <h4 className="footer-title">Our Links</h4>
                  <ul>
                    <li>
                      <Link to={"./"}>Home</Link>
                    </li>
                    <li>
                      <Link to={"./services"}>Services</Link>
                    </li>
                    <li>
                      <Link to={"./about-us"}>About Us</Link>
                    </li>
                    <li>
                      <Link to={"./contact"}>Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-sm-8 aos-item"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="800"
              ></div>
            </div>
          </div>
        </div>
        {/* <!-- footer bottom part --> */}
        <div className="footer-bottom">
          <div className="container">
            <span className="copyright-text">
              Copyright © 2024{" "}
              <a href="/" target="_blank" rel="noreferrer">
                Forsa
              </a>
              . All rights reserved.
            </span>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer5;
