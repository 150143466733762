import { Link } from "react-router-dom";
//Layouts
import Header from "./../layouts/Header5";
import Footer from "./../layouts/Footer5";
import PageTitle from "./../layouts/PageTitle";

//Components
import FooterSingUp from "./../components/FooterSingUp";

//images
import pic1 from "./../assets/images/services/pic-1.webp";
import pic2 from "./../assets/images/services/pic-2.jpg";
import pic3 from "./../assets/images/services/pic-3.webp";
import pic4 from "./../assets/images/services/pic-4.jpg";
import pic5 from "./../assets/images/services/pic-5.jpeg";
import pic6 from "./../assets/images/services/pic-6.jpg";

const serviceData = [
  {
    image: pic1,
    title: "Electric Generators",
    paragraph:
      "Empower your company with robust Electric Generators, ensuring uninterrupted energy for a world that never sleeps.",
  },
  {
    image: pic2,
    title: "Control Panels",
    paragraph:
      "Navigate efficiency effortlessly with our Control Panels, orchestrating seamless management for optimized operations and control.",
  },
  {
    image: pic3,
    title: "Security Systems",
    paragraph:
      "Fortify your environment with state-of-the-art Security Systems, safeguarding what matters most with cutting-edge surveillance and protection.",
  },
  {
    image: pic4,
    title: "Electrical Installations",
    paragraph:
      "Illuminate possibilities with precise Electrical Installations, a symphony of expertise bringing power to every corner of your space.",
  },
  {
    image: pic5,
    title: "ATS Units",
    paragraph:
      "Transition effortlessly and seamlessly with ATS Units, ensuring uninterrupted power flow and reliability when you need it the most.",
  },
  {
    image: pic6,
    title: "Residential Networks",
    paragraph:
      "Crafting connectivity, our Residential Networks intertwine your space with high-speed precision, making home a truly connected sanctuary.",
  },
];

function Services() {
  return (
    <>
      <Header />
      <div className="page-content bg-white">
        <PageTitle title="Our Services" parentPage="Home" childPage="Pages" />
        {/* <!-- Services --> */}
        <section className="content-inner bg-gray">
          <div className="container">
            <div className="row">
              {serviceData.map((data, ind) => (
                <div className="col-lg-4 col-md-6" key={ind}>
                  <div className="content-box2 m-b30">
                    <div className="dz-info">
                      <h3 className="title">{data.title}</h3>
                      <p>{data.paragraph}</p>
                    </div>
                    <div className="dz-media m-b30">
                      <img
                        src={data.image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100px",
                          objectFit: "cover",
                          backgroundPosition: "center",
                        }}
                      />
                    </div>
                    <div className="dz-bottom">
                      <Link to={"/contact-us"} className="btn-link">
                        GET STARTED<i className="fas fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="section-full dz-subscribe style-1 bg-gray">
          <FooterSingUp />
        </section>
      </div>
      <Footer />
    </>
  );
}
export default Services;
